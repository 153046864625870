/* Custom checkbox styles */
.custom-checkbox {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  
  .custom-checkbox input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid #d1d5db;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  }
  
  .custom-checkbox input[type="checkbox"]:checked + .checkmark {
    background-color: #4f46e5;
    border-color: #4f46e5;
  }
  
  .custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
    display: block;
  }
  
  .custom-checkbox .checkmark::after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  /* Tab content animations */
  .fade-enter {
    opacity: 0;
    transform: translateX(10%);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateX(-10%);
    transition: opacity 300ms, transform 300ms;
  }
  