@import "@fontsource/oswald"; /* Defaults to weight 400 */
/* src/index.css */
@font-face {
  font-family: 'Glacial Indifference';
  src: url('./assets/fonts/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('./assets/fonts/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
