/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/oswald"; /* Defaults to weight 400 */
@import "@fontsource/playfair-display"; /* Defaults to weight 400 */
/* src/index.css */
@font-face {
  font-family: 'Glacial Indifference';
  src: url('./assets/fonts/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('./assets/fonts/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

.access-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border-radius: 8px;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}


.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}
